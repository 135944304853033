import Typography from "@mui/material/Typography"
import { useIsSmallScreen } from "static/ts/hooks";

const HeadingTitle = ({text}:{text:string}) => {
  const isSmallScreen = useIsSmallScreen();
  return (
    <Typography
    component="div"
    variant={isSmallScreen ? 'h3' : 'h1'}
    sx={{
      fontSize: isSmallScreen ? '2.5rem' : '3.5rem',
      fontWeight: 'bold',
      margin: 'auto',
      textAlign: 'center',
      paddingBottom: 5
    }}
  >
    {text}
  </Typography>
  )
}

export default HeadingTitle