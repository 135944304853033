import Container from "@mui/material/Container";
import HeadingTitle from "components/00_atoms/HeadingTitle";
import { ReactNode } from "react";

const HeadingSection = ({title, children}:{title:string, children:ReactNode}) => {
  return (
    <Container>
      <HeadingTitle text={title}/>
      {children}
    </Container>
  )
}

export default HeadingSection