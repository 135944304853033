import { Box } from '@mui/material';
import './layout.scss'
import { Outlet } from 'react-router-dom';

const Layout = (props) => {
  return (
    <Box paddingTop={13}>
      <Outlet/>
    </Box>
    
  )
}

export default Layout
