import React, {useState} from 'react'
import IX              from 'static/img/iX'
import IInst           from 'static/img/iInst'
import HamburgerToggle from 'components/00_atoms/hamburgerToggle/hamburgerToggle'
import HeaderPopup     from './headerPopup/headerPopup'
import './header.scss'

const Header = () => {
  const [isShown, setIsShown] = useState(false)

  const popupHandler = (e : React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    let id = e.currentTarget.id

    if(id === 'popup-back' || id === 'header-item') {
      setIsShown(false)
    } else {
      setIsShown(true)
    }
  }

  return (
    <header className={'header-wrapper'} style={{
      backdropFilter: 'blur(10px)',
      background: 'rgb(255,255,255,0.5)'
    }}>
      <a href='/' className='icon'>
        <h1 className='icon'>CLOSM</h1>
        <p>Bringing out your uniqueness. </p>
      </a>
      <div className='sub'>
        <div className='SNS'>
          <a href="https://x.com/closm_official">
            <IX/>
          </a>
          <a href="https://www.instagram.com/closm.jp">
            <IInst/>
          </a>
        </div>
        <HamburgerToggle
          onClick = {popupHandler}
          isOpen  = {isShown}
        />
        <HeaderPopup
          isShown      = {isShown}
          popupHandler = {popupHandler}
        />
      </div>
    </header>
  )
}

export default Header
