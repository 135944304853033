import ScrollText from 'components/00_atoms/scrollText';
import './home.scss'
import { useIsSmallScreen } from 'static/ts/hooks';
import Overview from './Overview';
import Initiative from './Initiative';

const Home = () => {
  const isSmallScreen = useIsSmallScreen();

  return (
    <div className='home-wrapper'>
      <div className='home-header'>
        <h1>CLOSM</h1>
        <p>Official infomation</p>
      </div>
      <div className='home-contents'>
        <ScrollText
          className='child top'
          scrollRatio={2}
          fontSizeMin={isSmallScreen? 12 : 20}
          fontSizeRatio={isSmallScreen? 1.5 : 1.5}
        >
          あなたの価値を最大限に。<br />
          未来を創る新しいアイデンティティ。<br />
          <br />
        </ScrollText>
        <Overview/>
        <Initiative/>
      </div>
    </div>
  )
}

export default Home
