import ReactDOM from 'react-dom';
import { POP_UP_TARGET_ID } from 'utils/constants'
import './popup.scss';
import { PopupProps } from 'static/ts/interfaces';

const Popup = ({isShown, popupHandler, children}:PopupProps) => {
  // 特定の div 要素を指定します
  const portalTarget = document.getElementById(POP_UP_TARGET_ID);

  if (!portalTarget) return null;

  return ReactDOM.createPortal(
    <div
      className={'popup-back' + (isShown ? ' show' : '')}
      onClick={popupHandler}
      id='popup-back'
    >
      <div
        className='popup-wrapper'
        onClick={popupHandler}
        id='popup-wrapper'
      >
        {children}
      </div>
    </div>,
    portalTarget
  );
};

export default Popup;
