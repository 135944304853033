import { useIsSmallScreen } from "static/ts/hooks"
import HeadingSection from "components/01_molecules/HeadingSection"
import Card from "@mui/material/Card";
import { Box, CardContent, Typography, CardMedia, SvgIcon, Stack } from "@mui/material";
import ICLOSM from "static/img/iCLOSM";
import { initiativeContents } from "./contents";

const Initiative = () => {
  const isSmallScreen = useIsSmallScreen();
  return (
    <HeadingSection title="Initiative">
      <Stack spacing={10}>
        {initiativeContents.map((content, index)=>{return(
          <Card sx={{ display: 'flex', flexDirection:isSmallScreen? 'column': 'row'}} key={index}>
          <CardContent sx={{ display: 'flex', flexDirection:'column' }}>
          <Box sx={{display:"flex", flexDirection:'row', alignItems:'center', marginBottom:2}}>
            <SvgIcon fontSize="large" ><ICLOSM/></SvgIcon>
            <Typography component="div" variant="h5" sx={{marginLeft:2}}>
              {content.title}
            </Typography>
          </Box>
          <Box>
            <Typography
                variant="subtitle1"
                component="div"
                sx={{ color: 'text.secondary', fontWeight:'bold', whiteSpace: "pre-wrap"}}
                
              >
                {content.caption}
              </Typography>
            </Box>
          </CardContent>
          <CardMedia
            component="img"
            sx={{ width:isSmallScreen?'100%' :'30%' }}
            image={content.imageSrc}
            alt={`${content.title}_image`}
          />
        </Card>
        )})}
      </Stack>
      <Typography
        variant="h6"
        component="div"
        sx={{ color: 'text.secondary', fontWeight:'bold', marginY:10, textAlign:'right'}}
      >
        {'and more...'}
      </Typography>
    </HeadingSection>
  )
}

export default Initiative