import logo from "static/img/logo/closm_logo_only_black.png"
import { MapBusinessCard } from "./type";
import members from 'utils/hidden/members.json'

export const LOGO = logo

export const POP_UP_TARGET_ID = 'popup-target'

export const HEADER_ITEMS = [
  { label: 'TOP', to: '/' },
  // { label: 'CONTACT', to: '/contact' },
  // { label: 'TERMS', to: '/policy/terms' },
  // { label: 'LEGAL NOTICE', to: '/policy/legalNotice' },
];

export const MEMBER_NAMECARD: MapBusinessCard[] = members.map((member) => ({
  id: member.id,
  org: member.org,
  affiliation: member.affiliation,
  title: member.title ?? undefined,
  lastName: member.lastName,
  firstName: member.firstName,
  contact: member.contact ?? undefined,
  email: member.email ?? undefined,
  website: member.website ?? undefined,
  pathTXT: member.pathTxt
}));