import { Navigate, useLocation } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Box,
  Button,
} from "@mui/material";
import { generateHash, handleSaveContact } from "utils/functions";
import { BusinessCard } from "utils/type";
import { LOGO } from "utils/constants";

interface MemberProps {
  member: BusinessCard;
}

function Member({ member }: MemberProps): JSX.Element {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const pass = query.get("pass");

  // 独自のhash値と一致するか検証
  const validHashes = generateHash(member.id); // 独自に生成したhash値のリスト
  const isValid = validHashes === pass;

  if (!isValid) {
    return <Navigate to="/" />;
  }

  const handleClick = () => {
    handleSaveContact(member)
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
      <Card sx={{ maxWidth: 400, boxShadow: 3 }}>
        <CardContent>
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Avatar
              sx={{ width: 64, height: 64, mr: 2 }}
              src={LOGO}
              alt="User Avatar"
            />
            <Typography variant="h5" component="div">
            {member.lastName} {member.firstName}
            </Typography>
          </Box>
          <Typography variant="h6" color="text.secondary">
            {member.affiliation}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {member?.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            contact: {member.contact}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            E-mail: {member.email}
          </Typography>
        </CardContent>
        <Box sx={{ p: 2, textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            連絡する
          </Button>
        </Box>
      </Card>
      
    </Box>
  );
}

export default Member;
