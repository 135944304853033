import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useIsSmallScreen } from 'static/ts/hooks';

interface HeadingCardProps {
  imageSrc: string;
  title: string;
  caption: string;
}

const HeadingCard = ({ imageSrc, title, caption }: HeadingCardProps) => {
  const theme = useTheme();
  const isSmallScreen = useIsSmallScreen();

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: isSmallScreen ? 'column' : 'row',
        alignItems: 'center' ,
        width: '100%',
        background: 'inherit'
      }}
      elevation={0}
    >
      <CardMedia
        component="img"
        sx={{
          width: isSmallScreen ? '90%' : '50%',
          height: isSmallScreen ? 'auto' : '100%',
          objectFit: 'cover',
        }}
        image={imageSrc}
        alt="Image description"
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center', // 上下中央揃え
          flex: isSmallScreen ? '0 1 auto' : '1',
          p: theme.spacing(2),
          marginLeft: isSmallScreen ? 0 : 3,
        }}
      >
        <CardContent
          sx={{
            flex: '1 0 auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', // 上下中央揃え
            height: '100%',
          }}
        >
          <Typography
            component="div"
            variant={isSmallScreen ? 'h3' : 'h1'}
            sx={{
              fontSize: isSmallScreen ? '1.5rem' : '2.5rem',
              fontWeight: 'bold',
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="subtitle1"
            component="div"
            sx={{
              color: 'text.secondary',
              fontSize: isSmallScreen ? '1.2rem' : '1.8rem',
              whiteSpace: "pre-wrap"
            }}
          >
            {caption}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
};

export default HeadingCard;
