import Divider from '@mui/material/Divider';
import './footer.scss'
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { useIsSmallScreen } from 'static/ts/hooks';

const Footer = () => {
  const isSmallScreen = useIsSmallScreen();
  return (
    <Stack
      component={'footer'}
      spacing={1}
      padding={2}
      className='footer-wrapper'
    >
      <Stack spacing={3} padding={1} direction={isSmallScreen ? 'column' : 'row'}>
        <Stack>
          <h2>CONTACT</h2>
          <p>email : contact@closm.llc</p>
          <p>TEL : +81 80-8411-0725</p>
          <Link to="/contact" >{"問い合わせフォーム"}</Link>
        </Stack>
        <Stack>
          <h2>CLOSM i</h2>
          <Link to="/policy/terms" >{"利用規約"}</Link>
          <Link to="/policy/legalNotice" >{"特定商取引法に基づく表記"}</Link>
        </Stack>
      </Stack>
      <Divider variant="middle"/>
      <small>&copy; CLOSM Inc.</small>
    </Stack>
  )
}

export default Footer
