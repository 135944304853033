import React from 'react';
import './legalNotice.scss'

const LegalNotice = () => {
  return (
    <div className='legalNotice-wrapper'>
      <div className='legalNotice-header'>
        <h1>特定商取引法に基づく表記</h1>
      </div>
      <div className='legalNotice-contents'>
        <h2 id="%E8%B2%A9%E5%A3%B2%E6%A5%AD%E8%80%85%E3%81%AE%E5%90%8D%E7%A7%B0">販売業者の名称</h2><br/>
        <p>合同会社ＣＬＯＳＭ</p>
        <br/><br/><br/>
        <h2 id="%E6%89%80%E5%9C%A8%E5%9C%B0">所在地</h2><br/>
        <p>141-0001
        東京都品川区北品川5-7-14-1001</p>
        <br/><br/><br/>
        <h2 id="%E9%9B%BB%E8%A9%B1%E7%95%AA%E5%8F%B7">電話番号</h2><br/>
        <p>+81 80 8411 0725</p>
        <br/><br/><br/>
        <h2 id="%E3%83%A1%E3%83%BC%E3%83%AB%E3%82%A2%E3%83%89%E3%83%AC%E3%82%B9">メールアドレス</h2><br/>
        <p>contact@closm.llc</p>
        <br/><br/><br/>
        <h2 id="%E9%81%8B%E5%96%B6%E7%B5%B1%E6%8B%AC%E8%B2%AC%E4%BB%BB%E8%80%85">運営統括責任者</h2><br/>
        <p>川元　勇</p>
        <br/><br/><br/>
        <h2 id="%E8%BF%BD%E5%8A%A0%E6%89%8B%E6%95%B0%E6%96%99%E7%AD%89%E3%81%AE%E8%BF%BD%E5%8A%A0%E6%96%99%E9%87%91">追加手数料等の追加料金</h2><br/>
        <p>該当しない</p>
        <br/><br/><br/>
        <h2 id="%E4%BA%A4%E6%8F%9B%E3%81%8A%E3%82%88%E3%81%B3%E8%BF%94%E5%93%81%E8%BF%94%E9%87%91%E3%83%9D%E3%83%AA%E3%82%B7%E3%83%BC">交換および返品（返金ポリシー）</h2><br/>
        <p>決済後のキャンセルはお客様のご都合ではお受けできませんので、ご了承ください。</p>
        <br/>
        <h2 id="%E5%BC%95%E6%B8%A1%E6%99%82%E6%9C%9F">引渡時期</h2><br/>
        <p>注文後即日ご利用いただけますが、反映までに数分要する可能性があります。</p>
        <br/><br/><br/>
        <h2 id="%E5%8F%97%E3%81%91%E4%BB%98%E3%81%91%E5%8F%AF%E8%83%BD%E3%81%AA%E6%B1%BA%E6%B8%88%E6%89%8B%E6%AE%B5">受け付け可能な決済手段</h2><br/>
        <ul>
        <li>クレジットカード決済（VISA, MasterCard, American Express）</li>
        <li>Stripe決済</li>
        </ul>
        <br/><br/><br/>
        <h2 id="%E6%B1%BA%E6%B8%88%E6%9C%9F%E9%96%93">決済期間</h2>
        <p>クレジットカード決済の場合はただちに処理されます。</p>
        <br/><br/><br/>
        <h2 id="%E8%B2%A9%E5%A3%B2%E4%BE%A1%E6%A0%BC">販売価格</h2><br/>
        <p>各商品ページに記載の金額</p>
        <br/><br/><br/>
        <h2 id="%E3%82%BD%E3%83%95%E3%83%88%E3%82%A6%E3%82%A7%E3%82%A2%E3%81%AE%E5%8B%95%E4%BD%9C%E7%92%B0%E5%A2%83">ソフトウェアの動作環境</h2><br/>
        <p>推奨ブラウザ</p>
        <ul>
        <li>Google Chrome 最新版</li>
        <li>Safari 最新版</li>
        </ul>
        <p>※ 最新OSについては順次動作確認を行っておりますが、画面の一部が表示されない場合があります。</p>
        <p>上記の環境以外では、正常に動作しない、または画面表示が崩れる可能性があります。お使いのブラウザの種類やバージョンを確認の上、推奨環境でのご利用をおすすめします。
        本サイトではJavaScriptを使用しておりますので、無効にされている場合は、有効にしていただくようお願いいたします。</p>
        <br/>
      </div>
    </div>
  )
}

export default LegalNotice
