import React from 'react';
import './terms.scss'

const Terms = () => {
  return (
    <div className='terms-wrapper'>
      <div className='terms-header'>
        <h1>TERMS</h1>
      </div>
      <div className='terms-contents'>

      <h1>利用規約</h1>

      <br />
      
      <p>
        この利用規約（以下「本規約」といいます。）は、
        "i"（以下「本サービス」といいます。）の利用に関して、
        ユーザー（以下「利用者」といいます。）と運営者（以下「当社」といいます。）
        との間の権利義務関係を定めるものです。
      </p>
      <br />
      <br />


      <h2>第1条（適用）</h2>
      <br />
      <p>本規約は、本サービスを利用するすべての利用者に適用されます。</p><br/>

      <h2>第2条（利用登録）</h2><br/>
      <p>1. 本サービスを利用するには、所定の手続きを経て利用登録を行う必要があります。</p><br/>
      <p>2. 利用者は、登録情報に虚偽のないことを保証するものとします。</p><br/>

      <h2>第3条（禁止事項）</h2><br/>
      <p>利用者は、以下の行為を行ってはなりません。</p><br/>
        <ul>
            <li>1. 法令に違反する行為</li><br/>
            <li>2. 他の利用者または第三者の権利を侵害する行為</li><br/>
            <li>3. 本サービスの運営を妨害する行為</li><br/>
        </ul><br/>

      <h2>第4条（サービスの変更・中止）</h2><br/>
      <p>当社は、利用者に事前に通知することなく、本サービスの内容を変更または中止することがあります。</p><br/>

      <h2>第5条（免責事項）</h2><br/>
      <p>当社は、本サービスに関連して生じた損害について一切の責任を負わないものとします。</p><br/>

      <h2>第6条（規約の変更）</h2><br/>
      <p>当社は、本規約を変更することがあります。変更後の規約は、当社が別途定める場合を除き、公開された時点で効力を生じるものとします。</p><br/>

      <h2>第7条（準拠法・管轄）</h2><br/>
      <p>本規約の解釈に関しては、日本法を適用し、本サービスに関する紛争は、当社の本社所在地を管轄とする裁判所を専属的な管轄とします。</p><br/>

      <h2>第8条（お問い合わせ）</h2><br/>
      <p>本サービスに関するお問い合わせは、以下の連絡先までご連絡ください。</p><br/>
      <p>
        email : contact@closm.llc<br />
      </p><br/>

      <br/><br/><p>以上</p><br/>
        
      </div>
    </div>
  )
}

export default Terms
