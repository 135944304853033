const ICLOSM = () => {
  return (
    <svg viewBox="0 0 819.99994 819.99996">
      <g
        id="g493"
        transform="matrix(3.9268062,0,0,3.8960971,-694.04417,-364.80428)"
      ><g
          id="g154"
      >
        <path
        d="m 345.41,152.25 c -20.9,-21.99 -40.8,-27.77 -70.47,-25.94 -21.35,1.32 -43.14,6.73 -61.39,18.2 -13.79,8.67 -27.86,17.67 -32.45,34.32 -1.52,5.5 -2.15,10.52 -1.31,16.17 0.6,4.04 2.01,8.03 3.65,11.79 2.29,5.27 5.5,10.26 10.51,13.3 4.67,2.83 10.21,3.76 15.53,4.46 7.57,1 15.17,1.64 22.7,2.91 0.77,0.13 2.4,0.1 3.03,0.54 6.35,4.41 19.98,11.25 27.59,12.71 2.75,-0.49 5.5,-0.97 8.26,-1.46 5.21,-0.92 8.75,-2.31 12.29,2.53 2.37,3.24 3.17,7.73 4.45,11.34 1.34,3.76 2.67,7.51 4.01,11.27 0.14,0.41 2.67,7.32 2.61,7.35 14.72,-6.17 31.02,-7.57 45.58,-14.29 3.72,-1.72 7.31,-3.77 10.48,-6.37 1.5,-1.23 2.87,-2.57 4.15,-4.03 1.44,-1.64 2.6,-3.86 4.67,-4.73 2.9,-1.21 6.26,-0.78 9.2,-1.97 2.37,-0.97 4.22,-2.75 5.68,-4.81 1.61,-2.27 2.78,-4.83 3.99,-7.33 0.67,-1.38 1.33,-2.77 2.05,-4.13 0.54,-1.02 2.11,-2.73 2.27,-3.83 3.85,-26.38 -20.51,-50.57 -37.08,-68 z m 5.33,56.29 -16.28,1.69 -54.98,-34.86 -45.77,19.04 15.97,5.84 -28.57,5.84 -10.14,-9.22 1.23,-11.06 67.27,-27.34 71.26,38.7 v 11.37 z"
        id="path152"
      />
      </g></g>
    </svg>  
  )
}
export default ICLOSM
