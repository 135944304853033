import { Stack } from "@mui/material"
import FloatIn from "components/00_atoms/FloatIn"
import HeadingCard from "components/01_molecules/HeadingCard"
import { hcContents } from "./contents"
import { useIsSmallScreen } from "static/ts/hooks"
import HeadingSection from "components/01_molecules/HeadingSection"

const Overview = () => {
  const isSmallScreen = useIsSmallScreen();
  return (
    <HeadingSection title="Overview">
      <Stack direction="column" spacing={isSmallScreen?10:20}>
        {hcContents.map((card, index) => (
          <FloatIn key={index}>
            <HeadingCard 
              imageSrc={card.imageSrc}
              title={card.title}
              caption={card.caption}
            />
          </FloatIn>
        ))}
      </Stack>
    </HeadingSection>
  )
}

export default Overview