import SHA256 from "crypto-js/sha256";
import { BusinessCard } from "./type";

export function generateHash(input: string): string {
  return SHA256(input).toString();
}

export const generateVCard = (data: BusinessCard): string => {
  return [
    "BEGIN:VCARD",
    "VERSION:3.0",
    `N:${data.lastName};${data.firstName};;;`,
    `FN:${data.affiliation}`,
    `ORG:${data.org}`,
    data.title ? `TITLE:${data.title}` : "",
    data.contact ? `TEL:${data.contact}` : "",
    data.email ? `EMAIL:${data.email}` : "",
    data.website ? `URL:${data.website}` : "",
    "END:VCARD",
  ]
    .filter(Boolean) // 空文字列を削除
    .join("\n");
};

export const handleSaveContact = (member: BusinessCard) => {
  const vCardData = generateVCard(member);

  const blob = new Blob([vCardData], { type: "text/vcard" });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = `${member.lastName}_${member.firstName}.vcf`;
  a.click();

  URL.revokeObjectURL(url);
};