import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import Popup from 'components/00_atoms/popup/popup';
import { PopupProps } from 'static/ts/interfaces';
import { Link } from 'react-router-dom';
import { HEADER_ITEMS } from 'utils/constants';

const HeaderPopup = ({isShown, popupHandler}:PopupProps) => {

  return (
    <Popup
      isShown      = {isShown}
      popupHandler = {popupHandler}
    >
      <Stack direction="column" spacing={2}>
        {HEADER_ITEMS.map((button, index) => (
          <Button
            key={index}
            id="header-item"
            variant="outlined"
            component={Link}
            to={button.to}
            onClick={popupHandler}
            size='large'
            sx={{
              color: 'white', // テキストの色
              borderColor: 'white', // ボーダーの色
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)', // ホバー時の背景色
              },
            }}
          >
            {button.label}
          </Button>
        ))}
      </Stack>
    </Popup>

  )
}

export default HeaderPopup;
