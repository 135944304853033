
import Philosophy from "static/img/home/headerCard/Philosophy.webp"
import Vision from "static/img/home/headerCard/Vision.webp"
import Idea from "static/img/home/headerCard/Idea.webp"
import Worth from "static/img/home/headerCard/Worth.webp"

import iCapcha from 'static/img/home/20241105_キャプチャ素材.gif'
import { LOGO } from "utils/constants"



export const hcContents = [
  {
    imageSrc: LOGO,
    title: 'Callosum',
    caption: "Callosum(脳梁)の様に右脳と左脳の橋渡しになる存在に。\n理性と直感性の掛け合わせを。"
  },
  {
    imageSrc: Philosophy,
    title: 'Philosophy',
    caption: "個人の中に潜在的に存在する価値を最大化。\n自分という存在を唯一の存在に。",
  },
  {
    imageSrc: Vision,
    title: 'Vision',
    caption: "多数派や常識に縛られず、一人一人がお互いを尊重し合える場所を。",
  },
  {
    imageSrc: Idea,
    title: 'Idea',
    caption: "数学 × アート\n文学 × 科学\n右脳と左脳の掛け合わせに基づいた発想で全く新しいコンテンツを生み出す。",
  },
  {
    imageSrc: Worth,
    title: 'Worth',
    caption: "ジャンルに囚われない発想を元に新しい価値を提供。",
  },
];


export const initiativeContents = [
  {
    imageSrc: iCapcha,
    title: 'CLOSM i',
    caption: "想像・空想・イメージを元に作品を作成、投稿。\n他作品からの引用によるネットワーク（親子関係）から個々の世界観を広げる。",
  },
]